import React from "react"
import Avatar from "./Avatar.component"
import moment from "moment"

import { SalonCard } from "../data/types"
import { getAddressString } from "../util"

const SalonCardComponent = ({ salon }: { salon: SalonCard }) => {
  const address = getAddressString(salon.contact?.address, false, true)

  const renderOpenOrClosed = () => {
    const now = moment()
    const open = moment(salon.availability.opens, "hh:mm A")
    const close = moment(salon.availability.closes, "hh:mm A")

    if (now.isBetween(open, close)) {
      return (
        <p className="availability">
          <span className="open">Open</span> | Closes @{" "}
          {salon.availability.closes}
        </p>
      )
    } else {
      return (
        <p className="availability">
          <span className="closed">Closed</span> | Opens @{" "}
          {salon.availability.opens}
        </p>
      )
    }
  }

  return (
    <a className="salon-card" href={`/salon/${salon.salon.replace(/ /g, "-")}`}>
      <Avatar src={salon?.profilePicture} name={salon.salon} />
      <div className="salon-desc">
        <section className="salon-name-container">
          <p>{salon.salon}</p>
          <i className="fa-solid fa-heart"></i>
        </section>
        <p id="salon-address">{address}</p>
        <section className="salon-stats">
          {renderOpenOrClosed()}
          {/* <Rates rate={salon.rates} outOf={salon.outof} />
        <i className="fa-solid fa-heart small">{salon.likes}</i> */}
        </section>
      </div>
    </a>
  )
}
export default SalonCardComponent
