import React, {
  useReducer,
  createContext,
  type Dispatch,
  useEffect,
} from "react"
import {
  CartItemType,
  ClientType,
  GlobalReducerType,
  OrderType,
} from "../data/types"
import { ACTIONS } from "./actions"
import Loader from "../components/Modals/Loader.component"
import moment from "moment"
import { isBrowser } from "../util"

const INITIAL_STATE: GlobalReducerType = {
  uid: null,
  cart: [],
  loading: false,
  client: null,
  orderItems: [],
  orders: [],
}

export const GlobalStateContext = createContext(INITIAL_STATE)
export const GlobalDispatchContext = createContext((({ type, payload }) => ({
  type,
  payload,
})) as Dispatch<any>)

const getInitialState = () => {
  const initTeam = isBrowser() ? localStorage.getItem("state") : null
  if (initTeam) {
    return { ...INITIAL_STATE, ...JSON.parse(initTeam) }
  }

  return INITIAL_STATE
}

const addTocart = (
  state: GlobalReducerType,
  item: CartItemType
): GlobalReducerType => {
  let cart = state.cart
  const foundItemIndex = cart.findIndex(cartItem => cartItem._id === item._id)

  if (foundItemIndex >= 0) {
    cart[foundItemIndex].qty = item.qty
  } else {
    cart = [...cart, item]
  }

  return { ...state, cart }
}

const clearCartItems = (state: GlobalReducerType, items: string[]) => {
  const cart = state.cart.filter(_item => !items.includes(_item._id))
  return { ...state, cart }
}

const addOrders = (state: GlobalReducerType, items: OrderType[]) => {
  const addedOrderIds: string[] = []

  return {
    ...state,
    orders: [...items, ...state.orders].filter(_item => {
      const orderId = moment(_item.createdAt).valueOf().toString()
      if (!addedOrderIds.includes(orderId)) {
        addedOrderIds.push(orderId)
        return true
      }
      return false
    }),
  }
}

const reducer = (
  state = INITIAL_STATE,
  { type, payload }: { type: ACTIONS; payload: any }
): GlobalReducerType => {
  switch (type) {
    case ACTIONS.SET_AUTH:
      return { ...state, uid: payload as string | null }

    case ACTIONS.SET_CLIENT:
      return { ...state, client: payload as ClientType }

    case ACTIONS.ADD_CART_ITEM:
      return addTocart(state, payload)

    case ACTIONS.CLEAR_CART_ITEMS:
      return clearCartItems(state, payload)

    case ACTIONS.SET_CART:
      return { ...state, cart: payload as CartItemType[] }

    case ACTIONS.SET_ORDER_ITEMS:
      return { ...state, orderItems: payload as CartItemType[] }

    case ACTIONS.SET_LOADING:
      return { ...state, loading: payload as boolean }

    case ACTIONS.ADD_ORDERS:
      return addOrders(state, payload)

    case ACTIONS.SIGN_OUT:
      return { ...INITIAL_STATE }

    default:
      return INITIAL_STATE
  }
}

const GlobalContext = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  useEffect(() => {
    localStorage.setItem("state", JSON.stringify(state))
  }, [state])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <>{children}</>
        <Loader visible={state.loading} />
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContext
