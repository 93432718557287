import React from "react"
import { getAvatarColor } from "../util"
export enum Avatar_Type {
  MEDIUM = "medium",
  LARGE = "large",
  ICON = "icon",
}

type OwnProps = {
  type?: Avatar_Type 
  src?: string
  name: string
}

const Avatar = ({
  type = Avatar_Type.MEDIUM, 
  src,
  name,
}: OwnProps) => {
  const color = getAvatarColor(name)
  const style = { backgroundColor: color }
  if (!src)
    return (
      <span
        className={`avatar ${type}  name`}
        style={{ backgroundColor: color }}
      >
        {name[0].toUpperCase()}
      </span>
    )

  return <img src={src} alt="avatar" className={`avatar ${type} `} />
}

export default Avatar
