import React from "react"

const GiftvouchersPromo = ({
  onClickBuyVouchers,
}: {
  onClickBuyVouchers: () => void
}) => {
  return (
    <div className="giftvouchers-promo">
      <section>
        <h3>
          How about sending a gift voucher to your loved ones for their next
          salon appointment?
        </h3>

        <p>
          You can claim GLOWAPP Gift vouchers on your next salon appointment for
          any salon on GLOWAPP.
        </p>
        <button
          className="link-btn"
          onClick={onClickBuyVouchers}
        >{`Buy now >`}</button>
      </section>
      <img src="/img/vouchers.png" alt="glowapp gift-vouchers" />
    </div>
  )
}

export default GiftvouchersPromo
