import React from "react"

type OwnProps = {
  isOpen: boolean
  toggle: () => void
}

const DownloadApp = ({ toggle, isOpen }: OwnProps) => {
  if (!isOpen) return null
  return (
    <div className="custom-modal display-block tr-4">
      <div className="modal-main download-modal">
        <img
          className="appicon"
          alt="logo"
          src="https://www.glowapp.info/img/appiconv2.webp"
        />
        <span>GLOWAPP</span>
        <p>
          Download GLOWAPP mobile app <br /> to purchase Gift vouchers!
        </p>
        <a
          className="btn btn-secondary"
          href="https://www.glowapp.link/download"
          target="_blank"
        >
          Download now
        </a>
        <div className="stores">
          <a href="https://apps.apple.com/app/glowapp-where-you-glow-up/id1639030942">
            <img src="/img/playstore.svg" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.garlicbed.glowapp">
            <img src="/img/appstore.svg" alt="" />
          </a>
        </div>
        <button className="btn btn-link" onClick={toggle}>
          Download later
        </button>
      </div>
    </div>
  )
}

export default DownloadApp
