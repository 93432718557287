export enum ACTIONS {
  SET_AUTH = "SET_AUTH",
  SET_CLIENT = "SET_CLIENT",
  SET_LOADING = "SET_LOADING",

  ADD_CART_ITEM = "ADD_CART_ITEM",
  CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS",
  SET_CART = "SET_CART",
  ADD_ORDERS = "ADD_ORDERS",

  SET_ORDER_ITEMS = "SET_ORDER_ITEMS",

  SIGN_OUT = "SIGN_OUT",
}
