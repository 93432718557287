import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import Lottie from "react-lottie"
import { ReactScrolling } from "react-auto-glide"
import Layout from "../layouts/Layout"
import Navbar from "../components/Navbar.component"
import Footer from "../components/Footer.component"

import SalonCardComponent from "../components/SalonCard.component"
import { SalonCard } from "../data/types"
import { clientFeatures, salonFeatures } from "../data"
import CLIENT_GUIDE from "../data/resources/animations/client_guide.json"
import SALON_GUIDE from "../data/resources/animations/salon_guide.json"

import "react-auto-glide/dist/index.css"
import AboutUs from "../components/AboutUs.component"
import GiftvouchersPromo from "../components/GiftvouchersPromo.component"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContext.provider"
import { ACTIONS } from "../context/actions"
import DownloadApp from "../components/Modals/DownloadApp.component"
import { webAppUrl } from "../data/config"

type OwnProps = {
  data: {
    allSalons: {
      nodes: SalonCard[]
    }
  }
}

type FeatureType = {
  title: string
  desc: string
  icon: string
  key: string
}

export default function Home({ data }: OwnProps) {
  const [showDownloadModal, setshowDownloadModal] = useState<boolean>(false)
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const cartLength = state.cart.length

  const onClickSignOut = () => {
    dispatch({ type: ACTIONS.SIGN_OUT })
  }

  const toggleShowDownloadModal = () => setshowDownloadModal(!showDownloadModal)

  const renderSalon = (_salon: SalonCard, index: number) => {
    return (
      <div className="slide">
        <SalonCardComponent salon={_salon} />
      </div>
    )
  }

  const renderFeatures = (feature: FeatureType, index: number) => {
    return (
      <div className="col-sm-6" key={feature.key}>
        <div className="key-feature">
          <img src={feature.icon} alt={`glowapp-${feature.key}`} />
          <section>
            <h4>{feature.title}</h4>
            <p>{feature.desc}</p>
          </section>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Navbar cartLength={cartLength} onClickSignOut={onClickSignOut} />
      <div className="hero-container">
        <div className="container-lg">
          <div className="row hero">
            <div className="col-md-6 app-description">
              <div className="brand">
                <span>GLOWAPP</span>
                <h4>where you glow up</h4>
              </div>
              <p className="fw-light fs-3">
                Sri Lanka's first free customer-centric
                <br /> beauty service app!
              </p>
              <p className="mt-5">Are you a salon owner?</p>
              <div className="gets-started-wrapper">
                <a className="gets-started" href={webAppUrl}>
                  Get's started
                </a>
              </div>

              <div className="download">
                <h5>Download</h5>
                <section>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.garlicbed.glowapp"
                    target="_blank"
                    itemProp="sameAs"
                  >
                    <img
                      src="/img/playstore.svg"
                      alt="download glow app on playstore"
                      className="store"
                    />
                  </a>

                  <a
                    href="https://apps.apple.com/app/glowapp-where-you-glow-up/id1639030942"
                    target="_blank"
                    itemProp="sameAs"
                  >
                    <img
                      src="/img/appstore.svg"
                      alt="download glow app on appstore"
                      className="store"
                    />
                  </a>
                </section>
              </div>
            </div>
            <div className="col-md-6 poster">
              <img
                className="cover"
                src="/img/cover.webp"
                alt="by Jayro Cerqueira da Silva from Pexels"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="description">
        <div className="container-lg mt-3">
          <h1>Book your favorite salons online!</h1>
          <h3>Recently joined</h3>

          <div className="glide-container">
            <section className="gradient-left"> </section>
            <ReactScrolling
              mapper={renderSalon}
              list={data.allSalons.nodes}
              width="350px"
            />
            <section className="gradient-right"></section>
          </div>
        </div>
      </div>

      <div className="container-lg mb-5">
        <GiftvouchersPromo onClickBuyVouchers={toggleShowDownloadModal} />
      </div>

      <AboutUs />

      <div className="features-user-container">
        <div className="container-lg">
          <h1>GLOWAPP for Clients,</h1>
          <div className="row key-features-container">
            <div className="col-xl-9">
              <div className="row">{clientFeatures.map(renderFeatures)}</div>
            </div>
            <div className="col demo-container">
              <Lottie
                options={{
                  animationData: CLIENT_GUIDE,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    className: "guide-animation",
                  },
                }}
              />
              <img src="/img/blob.svg" className="blob-1" />
            </div>
          </div>
        </div>
      </div>

      <div className="features-user-container">
        <div className="container-lg">
          <h1>GLOWAPP for Salons,</h1>
          <div className="row key-features-container">
            <div className="col demo-container">
              <Lottie
                options={{
                  animationData: SALON_GUIDE,
                  autoplay: true,
                  loop: true,
                  rendererSettings: {
                    className: "guide-animation",
                  },
                }}
              />
              <img src="/img/blob.svg" className="blob-2" />
            </div>
            <div className="col-xl-9">
              <div className="row">{salonFeatures.map(renderFeatures)}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="features-user-container">
        <div className="container-lg">
          <div className="row key-features-container manage-business flex-column-reverse flex-xl-row">
            <div className="col-xl-6  ">
              <h2>
                Join GLOWAPP for FREE and manage your salon the smarter way!
              </h2>
              <label className="mt-3 mb-5">
                You can access the business portal on any device now!
                <br />
                At your office, with your laptop or on the go, with your phone!
                We got you covered!
              </label>
              <a className="gets-started dark" href={webAppUrl}>
                Get's started
              </a>
            </div>
            <div className="col demo-container  ">
              <img src="/img/mockups.png" className="devices mb-5" />
              <img src="/img/blob.svg" className="blob-1 xl-6" />
            </div>
          </div>
        </div>
      </div>

      <DownloadApp
        isOpen={showDownloadModal}
        toggle={toggleShowDownloadModal}
      />

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query allSalons {
    allSalons {
      nodes {
        id
        geohash
        salon
        contact {
          address {
            addressLine01
            city
            district
          }
          email
          phone
        }
        availability {
          closes
          opens
        }
        vendorId
        profilePicture
      }
    }
  }
`
