import React from "react"
import { Helmet } from "react-helmet"

type OwnProps = {
  children: JSX.Element | JSX.Element[]
}

const Layout = ({ children }: OwnProps) => {
  return (
    <>
      <Helmet>
        <meta http-equiv="content-type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-language" content="en" />
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://www.glowapp.info/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#b3a1cb" />
        <meta property="al:ios:app_name" content="GLOWAPP LK" />
        <meta property="al:ios:app_store_id" content="1639030942" />
        <meta property="og:title" content="GLOWAPP - where you glow up" />
        <meta
          property="og:image"
          content="https://www.glowapp.info/img/appiconv2.webp"
        />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:type" content="company" />
        <meta property="og:site_name" content="GLOWAPP" />
        <meta
          name="og:description"
          content="GLOWAPP empowers the Sri Lankan beauty services industry with a lot of innovative features. It works as an inter-actor between the beauty service providers and customers handling the appointment effectively in a hassle-free platform."
        />
        <meta
          name="description"
          content="GLOWAPP empowers the Sri Lankan beauty services industry with a lot of innovative features. It works as an inter-actor between the beauty service providers and customers handling the appointment effectively in a hassle-free platform."
        />
        <meta property="og:url" content="https://www.glowapp.info/" />
        <meta
          name="keywords"
          content="glow, glowapp, glow app, download glow app, download glowapp, book visit, book appointment, book haircut, book manicure, book in salon, salon system, spa software, beauty software, salon app, scheduling app, hair salon software, best salon software, Salon system, top salon software, Spa system, Appointment Scheduling, salon program, booking software, system, appointment system, scheduling software, online booking, salon program, salon management software, salon programs, scheduling applications, appointment schedule, appointment manager, booking app, nail software, spa, hair, nail salon, beauty, yoga, Shedul, mindbody, booksy, salon iris, best, free, pos, crm, point of sale, calendar system, calendar, reminders, salon supplies, salon management, bookings app, appointment app, salon software, best salons, free booking system, salon, appointment"
        />
        <link rel="apple-touch-icon" href="/logo192.png" />

        <link rel="manifest" href="/manifest.json" />

        <meta
          name="apple-mobile-web-app-title"
          content="GLOWAPP - where you glow up"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/glowapplk"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Alata&family=Roboto:wght@100;300;400;500&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#b3a1cb" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <title>GLOWAPP</title>
        <script type="application/ld+json">
          {`{
              "@context":"https://schema.org",
              "@graph":
                [
                  {
                    "@type":"MobileApplication",
                    "@id":"https://www.glowapp.info/#about-us",
                    "url":"https://www.glowapp.info/",
                    "name":"GLOWAPP",
                    "about":"GLOWAPP is the Sri Lanka’s first online appointment handling application for the Salons. It enables the clients to place their appointment easily and the Salon management to manage the client appointments with almost no effort.!",
                    "operatingSystem":[
                        "ANDROID",
                        "iOS"
                    ],
                    "applicationCategory":"LifestyleApplication",
                    "applicationSubCategory":"BusinessApplication",
                    "image":"https://www.glowapp.info/img/appiconv2.webp",
                    "installUrl":"https://www.glowapp.link/download",
                    "copyrightHolder":"Garlic Bed PVT LTD",
                    "copyrightYear":"2023",
                    "countryOfOrigin":"Sri Lanka",
                    "creator":"Garlic Bed PVT LTD",
                    "screenshot":"https://www.glowapp.info/img/screens.webp",
                    "review":[
                        {
                          "@type":"Review",
                          "reviewRating":{
                              "@type":"Rating",
                              "ratingValue":"5"
                          },
                          "author":{
                              "@type":"Person",
                              "name":"Moksha Damsarani"
                          }
                        }
                    ],
                    "offers":{
                        "@type":"Offer",
                        "price":"0",
                        "priceCurrency":"LKR"
                    }
                  },
                  {
                    "@type":"Organization",
                    "@id":"https://www.glowapp.info/#glowapp-org",
                    "name":"GLOWAPP",
                    "url":"https://www.glowapp.info/",
                    "logo":{
                        "@type":"ImageObject",
                        "inLanguage":"en-US",
                        "@id":"https://www.glowapp.info/#glowapp-logo",
                        "url":"https://www.glowapp.info/img/appiconv2.webp",
                        "contentUrl":"https://www.glowapp.info/img/appiconv2.webp",
                        "width":1024,
                        "height":1024,
                        "caption":"GLOWAPP"
                    },
                    "image":{
                        "@id":"https://www.glowapp.info/#about-us-img"
                    },
                    "sameAs":[
                        "https://www.facebook.com/glowapplk",
                        "https://www.linkedin.com/posts/garlicbed-com_glowapp-onlineappointmentmanagement-beautyindustry-activity-7084068837423284224-i7ml/",
                        "https://www.instagram.com/glowapplk/"
                    ]
                  },
                  {
                    "@type": "WebSite",
                    "@id": "https://www.glowapp.info/#website",
                    "url": "https://www.glowapp.info/",
                    "name": "GLOWAPP",
                    "description": "GLOWAPP is the Sri Lanka’s first online appointment handling application for the Salons. It enables the clients to place their appointment easily and the Salon management to manage the client appointments with almost no effort.!",
                    "publisher": { "@id": "https://www.glowapp.info/#glowapp-org" },
                    "inLanguage": "en-US"
                  },
                  {
                    "@type": "WebPage",
                    "@id": "https://www.glowapp.info/",
                    "url": "https://www.glowapp.info/",
                    "name": "GLOWAPP | Where you glow up",
                    "isPartOf": { "@id": "https://www.glowapp.info/#website" },
                    "about": { "@id": "https://www.glowapp.info/#glowapp-org" },
                    "image": { "@id": "https://www.glowapp.info/#about-us-img" },
                    "description": "GLOWAPP is the Sri Lanka’s first online appointment handling application for the Salons. It enables the clients to place their appointment easily and the Salon management to manage the client appointments with almost no effort.!",
                    "breadcrumb": { "@id": "https://www.glowapp.info/#breadcrumb" },
                    "inLanguage": "en-US",
                    "potentialAction": [
                      { "@type": "ReadAction", "target": ["https://www.glowapp.info/"] }
                    ]
                  },
                  {
                    "@type": "BreadcrumbList",
                    "@id": "https://www.glowapp.info/#breadcrumb",
                    "itemListElement": [
                      { "@type": "ListItem", "position": 1, "name": "Home" }
                    ]
                  }
                ]
            }`}
        </script>

        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1354891995084382');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=1354891995084382&ev=PageView&noscript=1"
          />`}
        </noscript>
      </Helmet>
      <div className="body">{children}</div>
    </>
  )
}

export default Layout
